import React, { useState, useEffect, memo, useRef } from "react";
import "../../home.scss";
import ButtonLoader from "../../../../commons/components/Loader/ButtonLoader";
import OtpInput from "react-otp-input";
import { Row, Col, Input, Label, Alert } from "reactstrap";
import Select, { components } from "react-select";
import { phone_codes } from "../../../../utils/locales";
import axios from "axios";
import {
  GVLINK_OTP,
  MOBILE_NO_ERROR,
  XOXO_LINK_OTP_SMS_ACTION,
} from "../../../../commons/constants/app.constants";
import SpecialRedemptionModal from "../SpecialRedemptionModal/SpecialRedemptionModal";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { Turnstile } from "@plum-xoxo/turnstile_captcha";
import {
  checkGivenEmailIsValid,
  checkGivenMobileNumberIsValid,
  makeRequestAuth,
} from "../../../../utils/globals";
import config from "../../../../../config/config.production.json";
import { toast } from "react-toastify";
import ApiService from "../../../../api/api.service";
import DeliveryAddress from "./DeliveryAddress";

const TEMPLATE_ID = "link_redemption_otp";
const SUBJECT = "OTP to access Xoxo Link reward";
const TURNSTILE_SITE_KEY = config.turnstileSiteKey;

interface PostParams {
  phone_code: string;
  phone: string;
  email: string;
  otp_id?: string;
  otp?: string;
  template_id?: string;
  subject?: string;
  token?: string;
  uniqueIdentifier?: string;
  action?: string;
  verify_mobile?: boolean;
}

const CollectVoucherCard = (props: any) => {
  const {
    delivery_method,
    linkCode,
    xc_selected,
    handleModal,
    selected,
    item,
    handleHtml,
    campaign,
    isUserVerified,
    redemption_check,
    customStyles,
    otpId,
    setOtpId,
    toggleTnc,
    verify_mobile = 0,
    setSelected = () => {},
    showCts,
    loading: redeemLoading,
    authenticate_email,
    maskedEmail,
    authenticate_phone,
    maskedPhone,
    isGvLink,
    pIInfoRequired,
    country_name,
    filter_group_id,
  } = props;
  const [renderTurnstileCaptcha, toggleTurnstileCaptcha] = useState(false);
  const [renderResendTurnstileCaptcha, toggleResendTurnstileCaptcha] =
    useState(false);
  const [showSpecialRedemptionModal, setShowSpecialRedemptionModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableCollectVoucherBtn, setdisableCollectVoucherBtn] =
    useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isdCode, setIsdCode] = useState("");
  const [isdCodeObject, setIsdCodeObject] = useState<any>({
    country_id: 99,
    name: "India",
    phonecode: "+91",
    iso: "IN",
  });
  const [selectedUserInfo, setSelectedUserInfo] = useState<any>("email");
  const [phoneCodesUi, setPhoneCodesUi] = useState<any>([]);
  const [otpSuccess, setOtpSuccess] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [showInputForm, setShowInputForm] = useState(false);
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [activeSection, setActiveSection] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({ value: "India" });
  const [disableStickyFooter, setDisableStickyFooter] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState<any>("");

  const isMerchandise = config.merch_filter_group_id === filter_group_id;

  const updateState = (state: any) => {
    return 0;
  };
  let intervalRef = useRef<any>();

  const decreaseNum = () => setSeconds((prev) => prev - 1);
  const otpInputRef = useRef<any>();

  useEffect(() => {
    if (otpId) {
      intervalRef.current = setInterval(decreaseNum, 1000);
      return () => clearInterval(intervalRef.current);
    }
  }, [otpId]);

  useEffect(() => {
    const selectedUserInfoUpdated =
      delivery_method === 2 || verify_mobile === 1 || authenticate_phone
        ? "phone"
        : "email";
    setSelectedUserInfo(selectedUserInfoUpdated);
  }, [delivery_method, authenticate_email, authenticate_phone]);

  useEffect(() => {
    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = getUnicodeFlagIcon(item.iso) + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });

    setPhoneCodesUi(phone_codes_ui);
    otpInputRef.current &&
      otpInputRef.current.focus &&
      otpInputRef.current.focus();
  }, [otpId]);

  useEffect(() => {
    const phone_codes_ui =
      phone_codes &&
      phone_codes.map((item, index) => {
        let obj = item;
        obj.label = getUnicodeFlagIcon(item.iso) + " " + item.phonecode;
        obj.value = item.country_id;
        return obj;
      });

    !pIInfoRequired && getDefaultIsd(phone_codes_ui);
    fetchCountries();
  }, []);

  const fetchCountries = () => {
    ApiService.fetchCountries()
      .then((data: any) => {
        if (data.fetchCountries.success) {
          if (data.fetchCountries.data.length) {
            let countryList: any = data.fetchCountries.data;
            const itemData: any = item;
            countryList = countryList.filter((item: any) => {
              if (
                itemData &&
                itemData.country_code.toLowerCase() == item.iso.toLowerCase()
              ) {
                return true;
              }
            });
            countryList = countryList.map((item: any) => {
              const countryObj = {
                label: item.name,
                value: item.name,
                countryCode: item.iso,
                countryId: item.country_id,
              };
              setSelectedCountry(countryObj);
              return countryObj;
            });
            setCountryList(countryList);
          } else {
            setCountryList([]);
          }
        } else {
          toast.error("Error in fetching country list");
        }
      })
      .catch((err: any) => console.log(err));
  };

  const fetchCityList = (stateId: any) => {
    ApiService.fetchStateCities(stateId)
      .then((data: any) => {
        if (data.fetchCities.success && data.fetchCities.success == "1") {
          if (data.fetchCities.data.length) {
            const locaCitiesList = data.fetchCities.data.map((item: any) => {
              return { label: item.name, value: item.name };
            });
            setCityList(locaCitiesList);
          }
        } else {
          toast.error("Error in fetching states list");
        }
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    if (email && email.length > 0) {
      const emailValidCheck = checkGivenEmailIsValid(email);
      emailValidCheck && setIsEmailValid(emailValidCheck);
      disableBtn && setDisableBtn(false);
    } else {
      !disableBtn && setDisableBtn(true);
    }
  }, [email]);
  useEffect(() => {
    if (phone && phone.length > 0) {
      const phoneValidCheck = checkGivenMobileNumberIsValid(phone);
      phoneValidCheck && setIsPhoneValid(phoneValidCheck);
      disableBtn && setDisableBtn(false);
    } else {
      !disableBtn && setDisableBtn(true);
    }
  }, [phone]);

  const redeemVoucher = () => {
    if (verify_mobile) {
      setShowSpecialRedemptionModal(true);
      setLoading(false);
    } else {
      collectVoucher();
    }
  };

  useEffect(() => {
    if (otpValue.length === 6) {
      disableCollectVoucherBtn && setdisableCollectVoucherBtn(false);
      redeemVoucher();
    } else {
      !disableCollectVoucherBtn && setdisableCollectVoucherBtn(true);
    }
  }, [otpValue]);

  useEffect(() => {
    props.setShowSpecialRedemptionModal &&
      props.setShowSpecialRedemptionModal(showSpecialRedemptionModal);
  }, [showSpecialRedemptionModal]);

  const handleEmailChange = (event: any) => {
    let email = event.target.value;
    setEmail(email);
  };

  const handleValidateInput = (field: string = "both") => {
    if (!email && !phone) {
      setDisableBtn(true);
      return false;
    }
    let validEmail = isEmailValid;
    let validPhone = isPhoneValid;
    if ((field === "email" || field === "both") && email.length > 0) {
      validEmail = checkGivenEmailIsValid(email);
    }
    if ((field === "phone" || field === "both") && phone.length > 0) {
      validPhone = checkGivenMobileNumberIsValid(phone);
    }
    setIsEmailValid(validEmail);
    setIsPhoneValid(validPhone);
    if (field === "email" && !validEmail) {
      setDisableBtn(true);
      return false;
    } else if (field === "phone" && !validPhone) {
      setDisableBtn(true);
      setOtpError("");
      return false;
    } else {
      setDisableBtn(false);
      return true;
    }
  };

  const handleMobileChange = (event: any) => {
    // this.setState({
    // mobileNumber: event.target.value,
    // });
    if (event && event.target) {
      let mobileNumber = event.target.value || "";
      let reg = /^\d+$/;

      if (
        !(mobileNumber.length == 0 && mobileNumber == "") &&
        (!reg.test(mobileNumber) || parseInt(mobileNumber) == 0)
      ) {
        return;
      }
      mobileNumber.length <= 16 && setPhone(mobileNumber);
    }
  };

  const handleISDchange = (event: any) => {
    setIsdCode(event.phonecode);
    setIsdCodeObject(event);
    setError(false);
    setErrorMsg("");
  };

  const getDefaultIsd = async (phone_codes_ui: any) => {
    ApiService.getCountryIsoCode2()
      .then((response) => {
        let countryCode = "IN";
        if (response && response.success && response.success === 1) {
          countryCode = response.country_code;
        }
        const phonecodeDetails = phone_codes_ui.find(
          (item: any) => item.iso == countryCode
        );
        setIsdCode(phonecodeDetails.phonecode);
        setIsdCodeObject(phonecodeDetails);
      })
      .catch(() => {
        let countryCode = "IN";
        const phonecodeDetails = phone_codes_ui.find(
          (item: any) => item.iso == countryCode
        );
        setIsdCode(phonecodeDetails.phonecode);
        setIsdCodeObject(phonecodeDetails);
      });
  };

  const handleSendOtp = async ({ token = "", resend = false }) => {
    await setSelected(selected === 0 ? 1 : selected);
    if (
      (!maskedPhone || verify_mobile) &&
      (!maskedEmail || verify_mobile) &&
      (disableBtn || (!email && !phone && !resend))
    )
      return;
    setLoading(true);

    let post_params: PostParams = {
      phone_code: isdCode,
      phone: verify_mobile ? phone : maskedPhone || phone,
      email: maskedEmail || email,
      template_id: TEMPLATE_ID,
      subject: SUBJECT,
      uniqueIdentifier: linkCode || "",
      action: pIInfoRequired ? GVLINK_OTP : XOXO_LINK_OTP_SMS_ACTION,
      verify_mobile: verify_mobile,
    };
    if (resend) {
      post_params.otp_id = otpId;
      post_params.token = token;
      setSeconds(60);
    } else if (otpId && otpValue) {
      post_params.otp_id = otpId;
      post_params.otp = otpValue;
    } else {
      post_params.token = token;
    }

    const query = "stores.mutation.plum_otp_service";

    const post_data = {
      query: query,
      tag: "stores",
      variables: {
        userData: JSON.stringify(post_params),
      },
    };

    const res = await makeRequestAuth({
      data: post_data,
      codeLength: linkCode.length,
    });

    if (res && res.data && res.data.plumOtpService) {
      toggleTurnstileCaptcha(false);
      toggleResendTurnstileCaptcha(false);
      const { plumOtpService } = res.data;
      if (plumOtpService.success === 1 && plumOtpService.otp_success === 1) {
        if (plumOtpService.validated) {
          if (verify_mobile) {
            setShowSpecialRedemptionModal(true);
            setLoading(false);
          } else {
            const initBtCl = true;
            xc_selected
              ? handleModal(
                  0,
                  xc_selected,
                  phone,
                  email,
                  isdCodeObject,
                  verify_mobile,
                  initBtCl,
                  null,
                  null,
                  null,
                  isGvLink ? null : XOXO_LINK_OTP_SMS_ACTION
                )
              : handleModal(
                  selected - 1,
                  xc_selected,
                  phone,
                  email,
                  isdCodeObject,
                  verify_mobile,
                  initBtCl,
                  null,
                  null,
                  null,
                  isGvLink ? null : XOXO_LINK_OTP_SMS_ACTION
                );
          }
        } else {
          setLoading(false);
          !resend && setOtpId(plumOtpService.otp_id);
        }
        setOtpSuccess(true);
        setOtpError("");
      } else {
        setLoading(false);
        setOtpError(plumOtpService.message);
        setOtpSuccess(false);
      }
    } else {
      setLoading(false);
      const errorMsg =
        (res && res.errorMessage) ||
        "Looks like you have exceeded the usage limit for this service. Please wait 1 hour and try again.";
      toast.error(errorMsg);
      setOtpError(errorMsg);
      setOtpSuccess(false);
    }
  };

  const onChangeUserDetailsSelect = (e: any) => {
    setSelectedUserInfo(e.target.value);
    setEmail("");
    setPhone("");
  };

  const tncAndHowToUse = (
    <Col className=" tnc-link mt-12">
      <div onClick={toggleTnc} className="cursor-pointer">
        Terms & conditions
      </div>{" "}
      |{" "}
      <div onClick={toggleTnc} className="cursor-pointer">
        How to use
      </div>
    </Col>
  );

  const redemptionMessage = (
    <Col className=" tnc-link mt-12 no-pointer">
      <div>Cancellation is not applicable once this voucher is issued.</div>
    </Col>
  );

  const renderOtp = () => (
    <>
      <div className="mb-4 d-flex align-items-start flex-row">
        <div>
          <a
            onClick={() => {
              setOtpId("");
              setOtpValue("");
              setOtpError("");
              setSeconds(60);
            }}
          >
            <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
          </a>
        </div>
        <div className="d-flex align-items-start flex-column ml-2">
          {phone || maskedPhone ? (
            <>
              <Row>
                <Col className="h6 text-black">Verify your phone number</Col>
              </Row>
              <Row>
                <Col className="text-color-ink-light">
                  Enter the OTP sent to the phone number{" "}
                  {!maskedPhone && isdCode + " - "} {phone || maskedPhone}
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="h6 text-black">Verify your email address</Col>
              </Row>
              <Row>
                <Col className="text-color-ink-light">
                  Enter the OTP we just sent to the email address{" "}
                  {maskedEmail || email}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
      <Row className="text-center mt-4">
        <Col className="column-center">
          <OtpInput
            ref={otpInputRef}
            shouldAutoFocus={true}
            value={otpValue}
            onChange={setOtpValue}
            numInputs={6}
            separator={
              <span>
                <strong> </strong>
              </span>
            }
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "0 0.2rem",
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "16px",
              borderRadius: "4px",
              color: "#000",
              border: "1px solid #BAC7D5",
            }}
            className="column-center"
          />
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <Col className="text-error">{otpError ? otpError : null}</Col>
      </Row>
      <Row className="text-center mt-4">
        <Col>
          {seconds > 0 && seconds < 60 ? (
            <div className="text-color-ink-light">
              Resend OTP in{" "}
              <span className="exclude-translation">{seconds}</span> second(s)
            </div>
          ) : renderResendTurnstileCaptcha ? (
            <Turnstile
              className="text-center"
              siteKey={TURNSTILE_SITE_KEY}
              onSuccess={(token: any) => handleSendOtp({ token, resend: true })}
              options={{ theme: "light" }}
              style={{ width: "100%" }}
            />
          ) : (
            <a
              onClick={() =>
                showCts
                  ? toggleResendTurnstileCaptcha(true)
                  : handleSendOtp({ resend: true })
              }
              className="blue-link"
            >
              Resend OTP
            </a>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <button
            className="btn btn-filled mt-2"
            onClick={() => redeemVoucher()}
            disabled={disableCollectVoucherBtn || loading || redeemLoading}
          >
            {loading || redeemLoading ? (
              <ButtonLoader />
            ) : verify_mobile === 1 ? (
              "Verify OTP"
            ) : (
              `Collect ${isMerchandise ? "Gift" : "Voucher"} `
            )}
          </button>
        </Col>
      </Row>

      {isMerchandise ? null : <Row>{redemptionMessage}</Row>}
      <Row>{tncAndHowToUse}</Row>
    </>
  );
  const CaretDownIcon = () => {
    return <img src="/img/icons/vector.svg" alt="caret-down" />;
  };

  const wrongOTP = (message: string) => {
    setOtpError(message);
    setOtpSuccess(false);
  };

  const collectVoucher = () => {
    const initBtCl = true;
    const uniqueIdentifier = linkCode || "";
    const action = pIInfoRequired ? GVLINK_OTP : XOXO_LINK_OTP_SMS_ACTION;
    xc_selected
      ? handleModal(
          0,
          xc_selected,
          phone,
          email,
          isdCodeObject,
          verify_mobile,
          initBtCl,
          otpId,
          otpValue,
          uniqueIdentifier,
          action,
          wrongOTP,
          firstName,
          lastName,
          deliveryDetails
        )
      : handleModal(
          selected - 1,
          false,
          phone,
          email,
          isdCodeObject,
          verify_mobile,
          initBtCl,
          otpId,
          otpValue,
          uniqueIdentifier,
          action,
          wrongOTP,
          firstName,
          lastName,
          deliveryDetails
        );
  };

  const cancelVoucher = () => {
    setOtpId("");
    setOtpValue("");
    setOtpError("");
  };

  const renderPIDataForm = () => {
    return (
      <>
        <div className="d-flex align-items-center mb-3">
          <div
            className="mr-3 cursor-pointer"
            onClick={() => setShowInputForm(false)}
          >
            <img src="/img/icons/back-arrow.svg" alt="backBtnIcon" />
          </div>
          <div className="d-flex flex-column align-items-start">
            <div className="h6 text-black m-0">Personal Details</div>
            <div className="text-color-ink-light">
              Enter your personal details
            </div>
          </div>
        </div>
        <Row>
          <Col md={6} xs={12} className="d-flex flex-column align-items-start">
            <Label className="text-black m-0">First Name</Label>
            <Input
              value={firstName}
              className="pi-input"
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Col>
          <Col md={6} xs={12} className="d-flex flex-column align-items-start">
            <Label className="text-black m-0">Last Name</Label>
            <Input
              value={lastName}
              className="pi-input"
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} xs={12} className="d-flex flex-column align-items-start">
            <Label className="text-black m-0">Email</Label>
            <Input
              onChange={handleEmailChange}
              value={email}
              type="email"
              className="pi-input"
              onBlur={() => handleValidateInput("email")}
              placeholder="Email address"
            />
            {!isEmailValid && !loading ? (
              <Row>
                <Col lg="12">
                  <p className="-phnError mb-1 py-0">
                    Please enter a valid email address
                  </p>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col className="d-flex flex-column align-items-start" md={6} xs={12}>
            <Label className="text-black m-0">Phone Number</Label>
            <div className="d-flex justify-content-start w-100">
              <Input
                className="mr-2 pi-input"
                style={{ width: "95px" }}
                disabled
                value={"+91"}
              />
              <Input
                value={phone}
                className="pi-input phone-input-field"
                placeholder="Phone number"
                onChange={(e) => {
                  if (e.target.value.length > 10) return;
                  handleMobileChange(e);
                }}
                onBlur={() => handleValidateInput("phone")}
              />
            </div>
            {!isPhoneValid && !loading && !otpError ? (
              <Row>
                <Col lg="12">
                  <p className="-phnError mb-1 py-0">{MOBILE_NO_ERROR}</p>
                </Col>
              </Row>
            ) : (
              <div className="text-color-ink-light">
                We will send OTP for Verification
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-4 mb-2">
          <Col>
            {renderTurnstileCaptcha ? (
              <Turnstile
                className="text-center"
                siteKey={TURNSTILE_SITE_KEY}
                onSuccess={(token: any) => handleSendOtp({ token })}
                options={{ theme: "light" }}
                style={{ width: "100%" }}
              />
            ) : (
              <button
                className="btn btn-filled mt-2"
                disabled={
                  disableBtn ||
                  loading ||
                  !firstName ||
                  !lastName ||
                  !email ||
                  !phone ||
                  !isEmailValid ||
                  phone.length < 10
                }
                onClick={() =>
                  showCts ? toggleTurnstileCaptcha(true) : handleSendOtp({})
                }
              >
                {loading ? <ButtonLoader /> : "Send OTP"}
              </button>
            )}
            <Alert
              color="dark"
              style={{
                backgroundColor: "#e9ecef",
                border: "none",
                padding: "16px 32px",
                marginTop: "8px",
              }}
            >
              <b>Disclaimer</b>: We collect your personal information as
              required by RBI regulations to provide our services and ensure
              compliance.
            </Alert>
          </Col>
        </Row>
        <Row>{tncAndHowToUse}</Row>
      </>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return showSpecialRedemptionModal ? (
    <SpecialRedemptionModal
      showModal={showSpecialRedemptionModal}
      setShowModal={setShowSpecialRedemptionModal}
      collectVoucher={collectVoucher}
      cancelVoucher={cancelVoucher}
      itemName={item.name}
    />
  ) : pIInfoRequired && showInputForm && !otpId ? (
    renderPIDataForm()
  ) : isMerchandise && showDeliveryAddress && !otpId ? (
    <DeliveryAddress
      activeSection={activeSection}
      updateState={updateState}
      countryList={countryList}
      cityList={cityList}
      setCityList={setCityList}
      fetchCitiesList={fetchCityList}
      selectedCountry={selectedCountry}
      setDisableStickyFooter={setDisableStickyFooter}
      setDeliveryDetails={setDeliveryDetails}
      handleSendOtp={handleSendOtp}
      showCts={showCts}
      renderTurnstileCaptcha={renderTurnstileCaptcha}
      toggleTurnstileCaptcha={toggleTurnstileCaptcha}
      loading={loading}
      customStyles={customStyles}
      setShowDeliveryAddress={setShowDeliveryAddress}
      deliveryDetails={deliveryDetails}
      toggleTnc={toggleTnc}
    />
  ) : (
    <div>
      {!otpId ? (
        <Row>
          <Col lg="4" xs="12">
            <div className="card_logo">
              {/* <img src={CLOUDINARY_URL + item.logo} alt='logo' /> */}
              <img className="img-fluid" src={item.image} alt="logo" />
            </div>
          </Col>
          <Col lg="8" xs="12">
            <div className="selected-title">{item.name ? item.name : ""}</div>
            <div>
              {item.expiry_and_validity ? (
                <div className="validity">
                  <strong>
                    {handleHtml(item.expiry_and_validity)} validity
                  </strong>
                </div>
              ) : null}
              {campaign && !isMerchandise ? (
                <h2>
                  {campaign.currency_code ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: campaign.currency_code,
                      }}
                    ></span>
                  ) : (
                    ""
                  )}{" "}
                  {campaign.amount ? campaign.amount : ""}
                </h2>
              ) : null}
              {item.description ? (
                <div
                  className="description"
                  style={{
                    maxHeight: "180px",
                    overflow: "scroll",
                    maxWidth: "80vw",
                  }}
                >
                  {isMerchandise ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></span>
                  ) : (
                    <>
                      This gift voucher can be used on the website at checkout
                      or in the store as per the terms and conditions.
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      ) : (
        renderOtp()
      )}

      {otpId ? null : (!isUserVerified && redemption_check) ||
        verify_mobile === 1 ? (
        <>
          <hr className="mt-4 mb-4" />
          {authenticate_email && maskedEmail && !verify_mobile ? (
            <div className="description">
              To collect your voucher, please verify your email address{" "}
              <b>{maskedEmail}</b> using OTP.
            </div>
          ) : authenticate_phone && maskedPhone && !verify_mobile ? (
            <div className="description">
              To collect your voucher, please verify your phone number{" "}
              <b>{maskedPhone} </b>using OTP.
            </div>
          ) : (
            <>
              <Row>
                <Col className="enter-info-discription">
                  Enter your information to collect{" "}
                  {isMerchandise ? "gift" : "voucher"}
                </Col>
              </Row>
              {verify_mobile === 0 &&
                !isUserVerified &&
                redemption_check &&
                !authenticate_email &&
                !authenticate_phone && (
                  <>
                    <Row
                      className="radio-inputs"
                      onChange={onChangeUserDetailsSelect}
                    >
                      <Col xs="6" lg="3" className="radio-buttons">
                        <input
                          type="radio"
                          value="email"
                          name="userinfo"
                          title="Email"
                          placeholder="Email address"
                          checked={selectedUserInfo === "email"}
                        />{" "}
                        <label className="label">
                          <span>Email address </span>
                        </label>
                      </Col>

                      <Col xs="6" lg="3" className="radio-buttons">
                        <input
                          type="radio"
                          value="phone"
                          name="userinfo"
                          title="Phone"
                          placeholder="Phone number"
                          checked={selectedUserInfo === "phone"}
                          maxLength={16}
                        />{" "}
                        <label className="label">
                          <span>Phone number </span>
                        </label>
                      </Col>
                    </Row>
                  </>
                )}

              {selectedUserInfo === "email" ? (
                <>
                  <Row>
                    <Col className="my-2 voucher__input_block" lg="12">
                      <div className="voucher__input">
                        <input
                          disabled={maskedEmail}
                          type="email"
                          value={maskedEmail || email}
                          onChange={handleEmailChange}
                          onBlur={() => handleValidateInput("email")}
                          placeholder="Email address"
                          id="email"
                          className={
                            !isEmailValid
                              ? "input-send-copy inputErrorOnly  error"
                              : "input-send-copy"
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  {!isEmailValid && !loading ? (
                    <Row>
                      <Col lg="12">
                        <p className="-phnError mb-1 py-0">
                          Please enter a valid email address
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : (
                <>
                  <Row>
                    <Col className="my-2 voucher__input_block" lg="12">
                      <div className="voucher__input d-flex">
                        {(!maskedPhone || verify_mobile === 1) && (
                          <Select
                            options={phoneCodesUi}
                            value={isdCodeObject}
                            isSearchable={true}
                            onChange={(event) => {
                              handleISDchange(event);
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                              DropdownIndicator,
                            }}
                            placeholder=""
                            // placeholder="+91"
                            noOptionsMessage={() => {
                              return "No Code";
                            }}
                            className="react-select -countryCode mr-2"
                            styles={customStyles}
                            // defaultValue={phone_codes[99].phonecode}
                          />
                        )}
                        {/* type="number" */}
                        <Input
                          placeholder="Phone number"
                          onChange={handleMobileChange}
                          onBlur={() => handleValidateInput("phone")}
                          value={
                            verify_mobile !== 1 && maskedPhone
                              ? maskedPhone
                              : phone
                          }
                          className={
                            !isPhoneValid
                              ? "inputErrorOnly error"
                              : "inputErrorOnly"
                          }
                          disabled={maskedPhone && verify_mobile !== 1}
                        />
                      </div>
                      <div className="d-flex">
                        <div className="-countryCode mr-2 d-none d-md-block"></div>
                        <div className="w-100">
                          {!isPhoneValid && !loading && !otpError ? (
                            <Row>
                              <Col lg="12">
                                <p className="-phnError mb-1 py-0">
                                  {MOBILE_NO_ERROR}
                                </p>
                              </Col>
                            </Row>
                          ) : null}
                          {otpError ? (
                            <Row className="">
                              <Col className="text-error">{otpError}</Col>
                            </Row>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          <Row className="mt-4 mb-2">
            <Col>
              {renderTurnstileCaptcha ? (
                <Turnstile
                  className="text-center"
                  siteKey={TURNSTILE_SITE_KEY}
                  onSuccess={(token: any) => handleSendOtp({ token })}
                  options={{ theme: "light" }}
                  style={{ width: "100%" }}
                />
              ) : (
                <button
                  className="btn btn-filled mt-2"
                  disabled={
                    (!maskedPhone || verify_mobile) &&
                    (!maskedEmail || verify_mobile) &&
                    (disableBtn || loading || redeemLoading)
                  }
                  onClick={() => {
                    if (isMerchandise && !showDeliveryAddress) {
                      setShowDeliveryAddress(true);
                      return;
                    }
                    showCts ? toggleTurnstileCaptcha(true) : handleSendOtp({});
                  }}
                >
                  {loading || redeemLoading ? (
                    <ButtonLoader />
                  ) : isMerchandise ? (
                    "Proceed to Collect Gift"
                  ) : (
                    "Send OTP"
                  )}
                </button>
              )}
            </Col>
          </Row>
          <Row>{tncAndHowToUse}</Row>
        </>
      ) : (
        <>
          <Row className="mt-4 mb-2">
            <Col>
              <button
                className="btn btn-filled mt-2"
                disabled={loading}
                onClick={(e) => {
                  if (pIInfoRequired && !showInputForm) {
                    setShowInputForm(true);
                    return;
                  }
                  if (isMerchandise && !showDeliveryAddress) {
                    setShowDeliveryAddress(true);
                    return;
                  }
                  setLoading(true);
                  e.preventDefault();
                  if (verify_mobile) {
                    setShowSpecialRedemptionModal(true);
                  } else {
                    const initBtCl = true;
                    xc_selected
                      ? handleModal(
                          0,
                          xc_selected,
                          phone,
                          email,
                          isdCodeObject,
                          verify_mobile,
                          initBtCl,
                          null,
                          null,
                          null,
                          isGvLink ? null : XOXO_LINK_OTP_SMS_ACTION,
                          firstName,
                          lastName
                        )
                      : handleModal(
                          selected - 1,
                          false,
                          phone,
                          email,
                          isdCodeObject,
                          verify_mobile,
                          initBtCl,
                          null,
                          null,
                          null,
                          isGvLink ? null : XOXO_LINK_OTP_SMS_ACTION,
                          firstName,
                          lastName
                        );
                  }
                }}
              >
                {loading ? (
                  <ButtonLoader />
                ) : (
                  `Collect ${isMerchandise ? "Gift" : "Voucher"} `
                )}
              </button>
            </Col>
          </Row>
          <Row>{redemptionMessage}</Row>
          <Row>{tncAndHowToUse}</Row>
        </>
      )}
    </div>
  );
};

export default CollectVoucherCard;
